<template>
  <div>
    <component :is="getComponent(icon)" :size="size"></component>
  </div>
</template>
<script>
import megaphoneIcon from './MegaphoneIcon.vue'
import UserAssign from './UserAssign.vue'
import DocumentIcon from './DocumentIcon.vue'
import ExcelIcon from './ExcelIcon.vue'
import ImagenIcon from './imageIcon.vue'
import PdfIcon from './PdfIcon.vue'
import ManageHistoryIcon from './ManageHistoryIcon.vue'
import LoadingIcon from '@/Icons/LoadingIcon.vue'
import ExpandArrowsInverseIcon from '@/Icons/ExpandArrowsInverseIcon.vue'
import Eyedropper from '@/Icons/Eyedropper.vue'
export default {
  props: ['icon', 'size'],
  components: {
    megaphoneIcon,
    UserAssign,
    DocumentIcon,
    ExcelIcon,
    ImagenIcon,
    PdfIcon,
    ManageHistoryIcon,
    LoadingIcon,
    ExpandArrowsInverseIcon,
    Eyedropper
  },
  methods: {
    getComponent(icon) {
      const components = {
        megaphoneIcon,
        userAssign: UserAssign,
        documentIcon: DocumentIcon,
        excelIcon: ExcelIcon,
        imageIcon: ImagenIcon,
        pdfIcon: PdfIcon,
        ManageHistoryIcon,
        loadingIcon: LoadingIcon,
        expandArrowsInverseIcon: ExpandArrowsInverseIcon,
        Eyedropper
      }
      return components[icon] || null
    }
  }
}
</script>