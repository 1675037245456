<template>
<svg :width="size ? size.width : 96" :height="size ? size.height : 67" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.1 2.07201L29.664 7.87201V29.928H8.87903V30H29.735V7.94501L24.1 2.07201Z" fill="#909090"/>
<path d="M24.031 2H8.80798V29.928H29.664V7.873L24.031 2Z" fill="#F4F4F4"/>
<path d="M8.65501 3.5H2.26501V10.327H22.365V3.5H8.65501Z" fill="#7A7B7C"/>
<path d="M22.472 10.211H2.39502V3.379H22.472V10.211Z" fill="#DD2025"/>
<path d="M9.052 4.534H7.745V9.334H8.77299V7.715L9 7.728C9.22057 7.72421 9.43906 7.68469 9.647 7.611C9.8293 7.5483 9.99701 7.44931 10.14 7.32C10.2855 7.19682 10.4002 7.04136 10.475 6.866C10.5753 6.5745 10.6111 6.2647 10.58 5.958C10.5737 5.73891 10.5353 5.52192 10.466 5.314C10.4029 5.16391 10.3092 5.0286 10.1909 4.91669C10.0726 4.80479 9.93235 4.71873 9.779 4.664C9.6464 4.616 9.50941 4.58117 9.37 4.56C9.26442 4.54371 9.15781 4.53502 9.05099 4.534M8.862 6.828H8.77299V5.348H8.96599C9.05117 5.34186 9.13665 5.35493 9.21609 5.38626C9.29554 5.41759 9.36693 5.46637 9.425 5.529C9.54531 5.69002 9.60959 5.886 9.608 6.087C9.608 6.333 9.60799 6.556 9.38599 6.713C9.22606 6.80097 9.04402 6.84157 8.862 6.828ZM12.533 4.521C12.422 4.521 12.314 4.529 12.238 4.532L12 4.538H11.22V9.338H12.138C12.4888 9.34762 12.8381 9.28816 13.166 9.163C13.4299 9.05832 13.6636 8.88958 13.846 8.672C14.0234 8.45246 14.1507 8.19684 14.219 7.923C14.2976 7.61287 14.3359 7.29392 14.333 6.974C14.3524 6.59616 14.3232 6.21739 14.246 5.847C14.1728 5.57436 14.0357 5.32309 13.846 5.114C13.6972 4.94516 13.515 4.80897 13.311 4.714C13.1358 4.63292 12.9515 4.57316 12.762 4.536C12.6867 4.52356 12.6104 4.51787 12.534 4.519M12.352 8.456H12.252V5.392H12.265C12.4712 5.36828 12.6797 5.40548 12.865 5.499C13.0007 5.60733 13.1112 5.7438 13.189 5.899C13.273 6.06234 13.3213 6.24161 13.331 6.425C13.34 6.645 13.331 6.825 13.331 6.974C13.3351 7.14564 13.324 7.3173 13.298 7.487C13.2672 7.66123 13.2102 7.8298 13.129 7.987C13.0371 8.13316 12.9129 8.25631 12.766 8.347C12.6426 8.4268 12.4965 8.46403 12.35 8.453M17.43 4.538H15V9.338H16.028V7.434H17.328V6.542H16.028V5.43H17.428V4.538" fill="#464648"/>
<path d="M21.7811 20.255C21.7811 20.255 24.9691 19.677 24.9691 20.766C24.9691 21.855 22.9941 21.412 21.7811 20.255ZM19.4241 20.338C18.9175 20.4499 18.4239 20.6138 17.9511 20.827L18.3511 19.927C18.7511 19.027 19.1661 17.8 19.1661 17.8C19.6434 18.6033 20.1987 19.3576 20.8241 20.052C20.3525 20.1223 19.8851 20.2185 19.4241 20.34V20.338ZM18.1621 13.838C18.1621 12.889 18.4691 12.63 18.7081 12.63C18.9471 12.63 19.2161 12.745 19.2251 13.569C19.1472 14.3976 18.9737 15.2143 18.7081 16.003C18.3442 15.3408 18.1559 14.5965 18.1611 13.841L18.1621 13.838ZM13.5131 24.354C12.5351 23.769 15.5641 21.968 16.1131 21.91C16.1101 21.911 14.5371 24.966 13.5131 24.354ZM25.9001 20.895C25.8901 20.795 25.8001 19.688 23.8301 19.735C23.0089 19.7218 22.1882 19.7796 21.3771 19.908C20.5913 19.1164 19.9147 18.2235 19.3651 17.253C19.7113 16.2524 19.9209 15.2097 19.9881 14.153C19.9591 12.953 19.6721 12.265 18.7521 12.275C17.8321 12.285 17.6981 13.09 17.8191 14.288C17.9376 15.093 18.1612 15.8791 18.4841 16.626C18.4841 16.626 18.0591 17.949 17.4971 19.265C16.9351 20.581 16.5511 21.271 16.5511 21.271C15.5738 21.5891 14.6538 22.0618 13.8261 22.671C13.0021 23.438 12.6671 24.027 13.1011 24.616C13.4751 25.124 14.7841 25.239 15.9541 23.706C16.5757 22.9142 17.1437 22.0817 17.6541 21.214C17.6541 21.214 19.4381 20.725 19.9931 20.591C20.5481 20.457 21.2191 20.351 21.2191 20.351C21.2191 20.351 22.8481 21.99 24.4191 21.932C25.9901 21.874 25.9141 20.993 25.9041 20.897" fill="#DD2025"/>
<path d="M23.954 2.077V7.95H29.587L23.954 2.077Z" fill="#909090"/>
<path d="M24.031 2V7.873H29.664L24.031 2Z" fill="#F4F4F4"/>
<path d="M8.97497 4.45701H7.66797V9.25701H8.69997V7.63901L8.92797 7.65201C9.14854 7.64821 9.36704 7.6087 9.57497 7.53501C9.75726 7.47228 9.92496 7.37329 10.068 7.24401C10.2124 7.12049 10.326 6.96506 10.4 6.79001C10.5003 6.4985 10.5361 6.1887 10.505 5.88201C10.4987 5.66291 10.4603 5.44593 10.391 5.23801C10.3278 5.08791 10.2342 4.95261 10.1159 4.8407C9.9976 4.72879 9.85732 4.64273 9.70397 4.58801C9.57077 4.53954 9.4331 4.50437 9.29297 4.48301C9.18739 4.46672 9.08079 4.45803 8.97397 4.45701M8.78497 6.75101H8.69597V5.27101H8.88997C8.97515 5.26486 9.06062 5.27794 9.14007 5.30927C9.21952 5.3406 9.29091 5.38938 9.34897 5.45201C9.46929 5.61302 9.53356 5.80901 9.53197 6.01001C9.53197 6.25601 9.53197 6.47901 9.30997 6.63601C9.15004 6.72397 8.96799 6.76357 8.78597 6.75001M12.456 4.44401C12.345 4.44401 12.237 4.45201 12.161 4.45501L11.926 4.46101H11.146V9.26101H12.064C12.4148 9.27062 12.7641 9.21116 13.092 9.08601C13.3559 8.98133 13.5896 8.81259 13.772 8.59501C13.9493 8.37546 14.0766 8.11984 14.145 7.84601C14.2235 7.53587 14.2618 7.21692 14.259 6.89701C14.2784 6.51916 14.2491 6.14039 14.172 5.77001C14.0988 5.49737 13.9616 5.2461 13.772 5.03701C13.6232 4.86817 13.441 4.73198 13.237 4.63701C13.0617 4.55592 12.8774 4.49616 12.688 4.45901C12.6126 4.44656 12.5363 4.44087 12.46 4.44201M12.278 8.37901H12.178V5.31501H12.191C12.3971 5.29129 12.6057 5.32848 12.791 5.42201C12.9266 5.53033 13.0372 5.66681 13.115 5.82201C13.1989 5.98534 13.2473 6.16461 13.257 6.34801C13.266 6.56801 13.257 6.74801 13.257 6.89701C13.261 7.06864 13.25 7.2403 13.224 7.41001C13.1931 7.58423 13.1362 7.7528 13.055 7.91001C12.9631 8.05616 12.8389 8.17931 12.692 8.27001C12.5686 8.3498 12.4225 8.38703 12.276 8.37601M17.353 4.46101H14.923V9.26101H15.951V7.35701H17.251V6.46501H15.951V5.35301H17.351V4.46101" fill="white"/>
</svg>

</template>
<script>
export default {
  props:['size']
}
</script>