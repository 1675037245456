<template>
  <svg :width="size ? size.width : 96" :height="size ? size.height : 67" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M28.781 4.40501H18.651V2.01801L2 4.58801V27.115L18.651 29.983V26.445H28.781C29.0878 26.4605 29.3883 26.354 29.6167 26.1486C29.8451 25.9432 29.9829 25.6557 30 25.349V5.50001C29.9827 5.19347 29.8448 4.90624 29.6164 4.70107C29.388 4.49589 29.0876 4.38946 28.781 4.40501ZM28.941 25.531H18.617L18.6 23.642H21.087V21.442H18.581L18.569 20.142H21.087V17.942H18.55L18.538 16.642H21.087V14.442H18.53V13.142H21.087V10.942H18.53V9.64201H21.087V7.44201H18.53V5.44201H28.941V25.531Z" fill="#20744A"/>
  <path d="M22.4871 7.439H26.8101V9.639H22.4871V7.439ZM22.4871 10.94H26.8101V13.14H22.4871V10.94ZM22.4871 14.441H26.8101V16.641H22.4871V14.441ZM22.4871 17.942H26.8101V20.142H22.4871V17.942ZM22.4871 21.443H26.8101V23.643H22.4871V21.443Z" fill="#20744A"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.34709 10.673L8.49309 10.55L9.84209 14.259L11.4361 10.397L13.5821 10.274L10.9761 15.54L13.5821 20.819L11.3131 20.666L9.78109 16.642L8.24809 20.513L6.16309 20.329L8.58509 15.666L6.34709 10.673Z" fill="white"/>
  </svg>
</template>
<script>
export default {
  props:['size']
}
</script>