<template>
<svg :width="size ? size.width : 96" :height="size ? size.height : 67" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M40 45H8V3H30L40 13V45Z" fill="#90CAF9"/>
<path d="M38.5 14H29V4.5L38.5 14Z" fill="#E1F5FE"/>
<path d="M21 23L14 33H28L21 23Z" fill="#1565C0"/>
<path d="M28 26.4L23 33H33L28 26.4Z" fill="#1976D2"/>
<path d="M31.5 26C32.3284 26 33 25.3284 33 24.5C33 23.6716 32.3284 23 31.5 23C30.6716 23 30 23.6716 30 24.5C30 25.3284 30.6716 26 31.5 26Z" fill="#1976D2"/>
</svg>
</template>
<script>
export default {
  props:['size']
}
</script>