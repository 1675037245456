
<template>
  <svg :width="size ? size.width : 34" :height="size ? size.height : 34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 33L10.8462 23.1538M10.8462 23.1538H2.23077M10.8462 23.1538V31.7692M33 33L23.1538 23.1538M23.1538 23.1538H31.7692M23.1538 23.1538V31.7692M1 1L10.8462 10.8462M10.8462 10.8462H2.23077M10.8462 10.8462V2.23077M33 1L23.1538 10.8462M23.1538 10.8462H31.7692M23.1538 10.8462V2.23077" stroke="#044389" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props:['size']
}
</script>