<template>
<svg :width="size ? size.width : 96" :height="size ? size.height : 67" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M40 45H8V3H30L40 13V45Z" fill="#90CAF9"/>
<path d="M38.5 14H29V4.5L38.5 14Z" fill="#E1F5FE"/>
<path d="M16 21H33V23H16V21ZM16 25H29V27H16V25ZM16 29H33V31H16V29ZM16 33H29V35H16V33Z" fill="#1976D2"/>
</svg>
</template>
<script>
export default {
  props:['size']
}
</script>